import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('loading', {
      $isLoading: 'isLoading',
    }),
  },
  methods: {
    ...mapActions('loading', {
      $startLoading: 'startLoading',
      $finishLoading: 'finishLoading',
      $forceFinishLoading: 'forceFinishLoading',
    }),
  },
};
