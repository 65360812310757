import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import middlewares from './middlewares';
// import not_exist from "@/views/door/index.vue";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(middlewares.authenticate);
router.onError((error) => {
    if (/loading chunk \d* failed./i.test(error.message)) {
        window.location.reload();
    }
});

export default router;