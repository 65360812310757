import baseAPI from '@/services/base';

export default {
    namespaced: true,
    state: () => ({
        insurance: {},
    }),
    getters: {
        Insurance: state => {
            return state.insurance;
        },
    },
    actions: {
        req_getInsurance: function({ commit }) {
            baseAPI()
                .get(`insurance`)
                .then(response => {
                    commit("getInsuranceSuccess", response.data.data);

                });
        },
        req_postInsurance: function({ dispatch }, input) {
            baseAPI()
                .post(`insurance`, input)
                .then(response => {
                    this.dispatch('insurance/req_getInsurance');
                });
        },

    },

    mutations: {
        getInsuranceSuccess(state, data) {
            state.insurance = data;
        },
        // postInsuranceSuccess(state, data) {
        //     this.dispatch('req_getInsurance');
        // }

    }
};