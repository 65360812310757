import Vue from 'vue';
import axios from 'axios';
import router from '@/router';
import { API_URL } from '@/config';
import store from '@/store';
import { getAccessToken, removeAuthToken } from '@/services/auth';

let total = 0;
let total2 = 1;
const handle500Error = () => {
    window.location.href = `${window.location.origin}/500`;
};
const handle404Error = () => {
    window.location.href = `${window.location.origin}/*`;
};
const request = () => {
    const axiosAPI = axios.create({
        baseURL: API_URL,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
    axiosAPI.interceptors.request.use((config) => {
        if (config.url && config.url !== 'spin/play') {
            total += 1;
            store.dispatch("loading/startLoading");
        }
        return config;
    }, (error) => Promise.reject(error));
    axiosAPI.interceptors.response.use(

        (response) => {
            if (total - 1 <= total2) {
                store.dispatch('loading/forceFinishLoading');
                total2 = 1;
                total = 1;
            } else {
                total2 += 1;
            }
            if (response.data.status && response.data.message) {
                Vue.$toast.success(response.data.message, {});
            }
            if (response.data.status === false && response.data.message) {
                Vue.$toast.error(response.data.message, {});
            }
            return response;
        },
        (error) => {

            // if (total - 1 <= total2) {
            //     store.dispatch('loading/forceFinishLoading');
            //     total2 = 1;
            //     total = 1;
            // } else {
            //     total2 += 1;
            // }
            // if (error.response.status === 500 || error.response.status === 405) {
            //     handle500Error();
            // }
            // if (error.response.status === 404) {
            //     handle404Error();
            // }
            // if (error.response.status === 401) {
            //     removeAuthToken();
            //     if (!localStorage.getItem('showToast')) {
            //         localStorage.setItem('showToast', true);
            //         router.push({ name: 'signin' });
            //         Vue.$toast.error('Your token has expired. Please sign in again', {});
            //     }
            // }

            return Promise.reject(error);
        },
    );

    return axiosAPI;
};

export default request;
