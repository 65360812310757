import baseAPI from '@/services/base';

export default {
    namespaced: true,
    state: () => ({
        list: {},
    }),
    getters: {
        List: (state) => state.list,
    },
    actions: {
        req_getListGame({ commit }) {
            baseAPI().get('/game/list').then((data) => {
                commit('GET_GAME_SUCCESS', data.data);
            });
        },

    },
    mutations: {
        GET_GAME_SUCCESS(state, data) {
            state.list = data;
        },
    },

};
