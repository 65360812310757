import Vue from "vue";
import Loading from "@/components/shared/Loading.vue";
import Toast from "vue-toastification";
import VModal from "vue-js-modal";
import Paginate from "vuejs-paginate";
import DatePicker from "vue2-datepicker";
import { VueReCaptcha } from "vue-recaptcha-v3";
import VuePhoneNumberInput from "vue-phone-number-input";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "vue-toastification/dist/index.css";
import "vue-js-modal/dist/styles.css";
import "vue2-datepicker/index.css";
import "./mixins";
import "./plugins";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import VueClipboard from "vue-clipboard2";
import moment from "moment";

Vue.prototype.moment = moment;
Vue.use(VueClipboard);
const EventBus = new Vue();
Vue.prototype.$eventBus = EventBus;

Vue.use(Toast, {});
Vue.use(VModal, {
  componentName: "VModal",
  dynamicDefault: { draggable: false, resizable: false }
});

Vue.component("loading", Loading);
Vue.component("paginate", Paginate);
Vue.component("datepicker", DatePicker);
Vue.component("vue-phone-number-input", VuePhoneNumberInput);
Vue.mixin({
  methods: {
    toDateTime(secs) {
      const t = new Date(1970, 0, 1);
      t.setSeconds(secs);
      return t.toLocaleString();
    },
    genarateCaptChaV3: async function(nameCaptCha) {
      await this.$recaptchaLoaded();
      var token = await this.$recaptcha(nameCaptCha);
      return token;
    }
  }
});
Vue.config.productionTip = false;
Vue.use(VueReCaptcha, { siteKey: "6Le4QNwZAAAAAFVjVRrWA6NhrGd2zGiVKfdRZUv3" });
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
