import axios from 'axios';
import { API_URL } from '@/config';

const axiosAPI = () => axios.create({
    baseURL: API_URL,
});

export const setAuthToken = (payload) => {
    localStorage.setItem('access_token', payload);
};

export const removeAuthToken = () => {
    localStorage.removeItem('access_token');
};

export const getAccessToken = () => localStorage.getItem('access_token');

export const signIn = async(email, password, authCode) => {
    localStorage.removeItem('showToast');
    const response = await axiosAPI().post('login', {
        email,
        password,
        authCode
    });

    return response.data;
};
export const signInWithMeta = async(address) => {
    localStorage.removeItem('showToast');
    const response = await axiosAPI().post('login-metamask', {
        address,
    });

    return response.data;
};

export const signUp = async(email, password, sponsor, token) => {
    const response = await axiosAPI().post('register', {
        email,
        password,
        sponsor,
        token,
    });
    return response.data;
};

export const forgot = async(User_Email, token) => {
    const response = await axiosAPI().post('forget', {
        User_Email,
        token,
    });
    return response.data;
};

export const isLoggedIn = () => !!localStorage.getItem('access_token');