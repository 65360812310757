import {
  isLoggedIn,
} from '@/services/auth';

const authenticate = (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requireAuth);
  if (requiresAuth && !isLoggedIn()) {
    return next({ name: 'signin' });
  }
    return next();
};

export default authenticate;
