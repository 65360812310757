function loadView(view) {
    return () =>
        import (`@/views/${view}.vue`);
}

const routes = [
    {
        path: "/",
        name: "dashboard",
        component: loadView("Dashboard"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/lucky-while",
        name: "LuckySpin",
        component: loadView("PrivateLuckySpin"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/top-trading",
        name: "TopTrading",
        component: loadView("PrivateTopTrading"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/wallet",
        name: "wallet",
        component: loadView("Wallet"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/profile",
        name: "profile",
        component: loadView("Profile"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/member",
        name: "member",
        component: loadView("Member"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/support",
        name: "support",
        component: loadView("PrivateSupport"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/game",
        name: "game",
        component: loadView("PrivateGame"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/insurance",
        name: "insurance",
        component: loadView("Insurance"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/static-agency",
        name: "staticAgency",
        component: loadView("staticAgency"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/sub-account",
        name: "sub-account",
        component: loadView("SubAccount"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/win-loss-report",
        name: "win-loss-report",
        component: loadView("WinLossReport"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/transaction-detail",
        name: "transaction-detail",
        component: loadView("TransactionDetail"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/signin-detail",
        name: "signin-detail",
        component: loadView("SignInDetail"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/signin",
        name: "signin",
        component: loadView("SignIn")
    },
    {
        path: "/signup",
        name: "signup",
        component: loadView("SignUp")
    },
    {
        path: "/forget-password",
        name: "forgot",
        component: loadView("Forgot")
    },
    { path: "/500", name: "500", component: loadView("errors/500") },
    { path: "*", name: "404", component: loadView("errors/404") },
    {
        path: "/Gsd354Sdfhr4/oiewh3454Has54",
        name: "Not_exist",
        component: loadView("door/Index"),
    },
];

export default routes;