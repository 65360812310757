import Vue from 'vue';
import Vuex from 'vuex';
import loading from './modules/loading';
import dashboard from './dashboard';
import topleader from './topleader';
import wallet from './wallet';
import wm555 from './wm555';
import profile from './profile';
import member from './member';
import insurance from './insurance';
import ticket from './ticket';
import game from './game';
import spin from './luckyspin'
Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,
    modules: {
        loading,
        dashboard,
        wallet,
        profile,
        member,
        insurance,
        wm555,
        ticket,
        game,
        spin,
        topleader
    },
    mutations: {
        HiddenChat: function() {}
    }
});