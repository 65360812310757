<template>
  <div id="app">
    <router-view />
    <loading v-show="$isLoading" />
  </div>
</template>
<script>
export default {
  created() {
    if (localStorage.getItem("access_token")) {
      this.$store.dispatch("dashboard/req_getDashboard", 1);
    }
  }
};
</script>
<style>
.grecaptcha-badge {
  visibility: hidden;
}
/* #page-wrapper {
  background: url(assets/img/bg/bg_main.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
} */
body {
  background: url("./assets/img/bg_main.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  margin: 0;
  padding: 0;
}
body:after {
  background: rgb(0 0 0 / 0.5);
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -10;
}
.table-responsive::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.table-responsive::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 6px;
  height: 5px;
  background-color: #f5f5f5;
}
.table-responsive::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background-color: #000000;
}
</style>
<style lang="scss">
.d-flex {
  display: flex;
}
.justify-content-between {
  justify-content: space-between;
}
.position-relative {
  position: relative;
}
@font-face {
  font-family: "Myriad Pro";
  src: url("~@/assets/fonts/MYRIADPRO-REGULAR.OTF") format("opentype");
}
@font-face {
  font-family: "UTM Helve";
  src: url("~@/assets/fonts/UTM\ Helve.ttf") format("opentype");
}
</style>
