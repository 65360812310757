import baseAPI from '@/services/base';

export default {
    namespaced: true,
    state: () => ({
        auth: {},
        infoMeta: {},
    }),
    getters: {
        Auth: state => {
            return state.auth;
        },
        InfoMeta: state => {
            return state.infoMeta;
        },
    },
    actions: {
        req_changePassword: function({ dispatch }, input) {
            baseAPI()
                .post(`profile/change-password`, input)
                .then(response => {
                    //dispatch('req_changePassword');
                });
        },
        req_infoMetamask: function({ commit }) {
            baseAPI()
                .get(`metamask/info`)
                .then(response => {
                    commit("getInfoMetaSuccess", response.data.data);
                    //dispatch('req_changePassword');
                });
        },
        req_confirmConnectWallet: function({ dispatch }, input) {
            baseAPI()
                .post(`metamask/confirm-connect`, input)
                .then(response => {
                    dispatch('req_infoMetamask');
                    // commit("getConfirmConnectWalletSuccess", response.data);
                });
        },
        req_disConfirmConnectWallet: function({ dispatch }, input) {
            baseAPI()
                .post(`metamask/disconnect-connect`, input)
                .then(response => {
                    dispatch('req_infoMetamask');
                    // commit("getConfirmConnectWalletSuccess", response.data);
                });
        },
        req_getAuth: function({ commit }) {
            baseAPI()
                .get(`auth/get-auth`)
                .then(response => {
                    commit("getAuthSuccess", response.data.data);
                });
        },
        // Change Auth
        req_changeAuth: function({ commit }, input) {
            // Request
            baseAPI()
                .post(`auth/confirm-auth`, input)
                .then(response => {
                    commit('changeAuthSuccess', response.data);
                })
        },

    },

    mutations: {
        getAuthSuccess(state, data) {
            state.auth = data;
        },
        getInfoMetaSuccess(state, data) {
            //console.log(data);
            state.infoMeta = data;
        },
        // auth
        changeAuthSuccess(state, data) {
            state.auth = !state.Auth;
        },

    }
};