import baseAPI from '@/services/base';

export default {
    namespaced: true,
    state: () => ({
        infoTopLeader: {},
        listTopLeader: {},
    }),
    getters: {
        InfoTopLeader: state => state.infoTopLeader,
        ListTopLeader: state => state.listTopLeader,
    },
    actions: {
        req_getInfoTopLeader({ commit }) {
            baseAPI()
                .get(`top-leader/get-info`)
                .then(response => {
                    commit("SET_GETINFOTOPLEADER", response.data.data);
                });
        },
        req_getListTopLeader({ commit }) {
            baseAPI()
                .get(`top-leader/get-list`)
                .then(response => {
                    commit("SET_GETLISTTOPLEADER", response.data.data);
                });
        },
    },
    mutations: {
        SET_GETINFOTOPLEADER(state, data) {
            state.infoTopLeader = data.infoTopLeader;
        },
        SET_GETLISTTOPLEADER(state, data) {
            state.listTopLeader = data.listTopLeader;
        },
    }
};
