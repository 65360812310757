import baseAPI from "@/services/base";
export default {
  namespaced: true,
  state: () => ({
    balance: {
      Voucher: 0,
      Main: 0,
      Ticket: 0
    },
    mission: [],
    reward: [],
    settings: {
      price: 0,
      trade: 0
    },
    statisLucky: {
      remaining_withdraw: 0,
      total_trade: 0,
      total_trade_week: 0,
      withdraw_this_week: 0,
      max_withdraw: 0,
      spin: 0
    }
  }),
  getters: {
    Balance: state => {
      return state.balance;
    },
    Mission: state => {
      return state.mission;
    },
    Reward: state => {
      return state.reward;
    },
    Settings: state => {
      return state.settings;
    },
    StatisLucky: state => {
      return state.statisLucky;
    }
  },

  actions: {
    req_getInfo: ({ commit }) => {
      baseAPI()
        .get("spin/info")
        .then(res => {
          if (res) {
            commit("SET_LUCKY_INFO", res.data.data);
          }
        });
    },
    req_postBuyTicker: ({ commit, dispatch }, input) => {
      baseAPI()
        .post("spin/buy", input)
        .then(res => {
          if (res) {
            dispatch("req_getInfo");
            commit("BUY_SUCCESS");
          }
        });
    },
    req_postWithdraw: ({ commit, dispatch }, input) => {
      baseAPI()
        .post("spin/withdraw", input)
        .then(res => {
          if (res) {
            dispatch("req_getInfo");
            commit("WITHDRAW_SUCCESS");
          }
        });
    }
  },

  mutations: {
    SET_LUCKY_INFO: (state, data) => {
      state.balance.Voucher = data.balance.Voucher;
      state.balance.Main = data.balance.Main;
      state.balance.Ticket = data.balance.Ticket;
      state.mission = data.list_mission;
      state.reward = data.list_reward;
      state.settings.price = data.price_ticket;
      state.settings.trade = data.total_trade;
      state.statisLucky = {
        remaining_withdraw: data.remaining_withdraw,
        total_trade: data.total_trade,
        total_trade_week: data.total_trade_week,
        withdraw_this_week: data.withdraw_this_week,
        max_withdraw: data.max_withdraw,
        spin: data.count_the_spins
      };
    },
    BUY_SUCCESS: function() {},
    WITHDRAW_SUCCESS: function() {}
  }
};
