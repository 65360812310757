export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const RESET = 'RESET';

export const state = {
  requestsCounter: 0,
};

export const mutations = {
  [INCREMENT](state) {
    state.requestsCounter += 1;
  },
  [DECREMENT](state) {
    if (state.requestsCounter === 0) return;
    state.requestsCounter -= 1;
  },
  [RESET](state) {
    state.requestsCounter = 0;
  },
};

export const actions = {
  startLoading({ commit }) {
    commit(INCREMENT);
  },

  finishLoading({ commit }) {
    commit(DECREMENT);
  },
  forceFinishLoading({ commit }) {
    commit(RESET);
  },
};

export const getters = {
  isLoading: (state) => !!state.requestsCounter,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
