import baseAPI from '@/services/base';

export default {
    namespaced: true,
    state: () => ({
        list: [],
        totalMember: 0,
        totalPage: 0,
        showTree: true,
        tree: {},
        detail: {},
        listStaticAgency: {},
        totalF: {
            F1: null,
            F2: null,
            F3: null,
        },
        totalEgg: 0
    }),

    getters: {
        List: state => {
            return state.list;
        },
        ListStaticAgency: state => {
            return state.listStaticAgency;
        },
        TotalMember: state => {
            return state.totalMember;
        },
        TotalPage: state => {
            return state.totalPage;
        },
        Tree: state => {
            return state.tree;
        },
        Showtree: state => {
            return state.showTree;
        },
        Detail: state => {
            return state.detail;
        },
        TotalF: state => {
            return state.totalF;
        },
        TotalEgg: state => {
            return state.totalEgg;
        }
    },

    actions: {
        // Add Member
        req_addMember({ dispatch, commit }, input) {
            // Request
            baseAPI().post("/member/add-member", input).then(function(data) {
                if (data) {
                    commit("member/addMemberSuccess", input.type, { root: true });
                }
            });
        },

        req_getStaticAgency({ commit }, input) {
            // Request
            baseAPI().post("/member/member-list-agency", input).then(function(data) {
                if (data) {
                    commit("getStaticAgencySuccess", data);
                }
            });
        },

        // Member
        req_getMemberList({ commit }, input) {
            // Request
            baseAPI().post("/member/member-list", input).then(function(data) {
                if (data) {
                    commit("getMemberList", data);
                }
            });
        },
        req_getMemberTree({ commit }) {
            // Request
            baseAPI().get("/member/member-tree").then(function(data) {
                if (data) {
                    commit("getMemberTree", data.data.data);
                }
            });
        },
        req_getDetail({ commit }, input) {
            // Request
            baseAPI()
                .get("/member/get-member-detail/" + input)
                .then(function(data) {
                    commit("getDetailSuccess", data);
                });
        }
    },

    mutations: {
        // Member
        addMemberSuccess() {},

        getStaticAgencySuccess(state, data) {
            state.listStaticAgency = data.data.data;
        },
        // List
        getMemberList(state, data) {
            state.totalMember = data.total_member;
            state.totalPage = data.total_page;
            state.list = data.list;
            state.totalF.F1 = data.f1;
            state.totalF.F2 = data.f2;
            state.totalF.F3 = data.f3;
            state.totalEgg = data.total_eggs;
        },
        // Tree
        getMemberTree(state, data) {

            state.tree = data.trees;
        },
        // Detail
        getDetailSuccess(state, data) {
            state.detail = data;
        }
    }
};