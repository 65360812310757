/* eslint-disable camelcase */

import Vue from 'vue';
import {
 ValidationProvider, ValidationObserver, extend,
} from 'vee-validate';
import {
 required, alpha_num, min, max, confirmed, numeric, min_value,
} from 'vee-validate/dist/rules';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

extend('required', {
  ...required,
  message: 'This field is required.',
});
extend('alpha_num', {
  ...alpha_num,
  message: 'This field can only contain alphabetic characters or numbers.',
});
extend('numeric', {
  ...numeric,
  message: 'This field can only consist of numbers.',
});
extend('min', {
  ...min,
  message: 'This field must be at least {length} characters long.',
});
extend('max', {
  ...max,
  message: 'This field must be at most {length} characters long.',
});
extend('confirmed', {
  ...confirmed,
  message: 'Please enter the same password as above.',
});
extend('min_value', {
  ...min_value,
  message: 'This field must be greater than {min}.',
});
