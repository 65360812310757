import baseAPI from '@/services/base';

export default {
    namespaced: true,
    state: () => ({
        history: {},
        totalPages: 1,
        currentPage: 1,
        listCoin: {}
    }),
    getters: {
        History: (state) => state.history,
        ListCoin: (state) => state.listCoin,
        TotalPages: (state) => state.totalPages,
    },
    actions: {
        req_changePasss({ commit }, input) {
            baseAPI().post('wm555/change-password', input).then((data) => {
                commit('CHANGEPASSWORD_SUCCESS', data);
            });
        },
        req_register({ commit }, input) {
            baseAPI().post('wm555/register', input).then((data) => {
                commit('REGISTER_SUCCESS', data);
            });
        },

        req_depositWM555({ commit }, input) {
            baseAPI().post('wm555/deposit', input).then((data) => {
                commit('DEPOSIT_SUCCESS', data.data.data);
            });
        },
        req_withdrawWM555({ commit }, input) {
            baseAPI().post('wm555/withdraw', input).then((data) => {
                commit('WITHDRAW_SUCCESS', data.data.data);
            });
        },
        req_getHistory({ commit }, input) {
            baseAPI().get('report/wallet', input).then((data) => {
                commit('GET_HISTORY_SUCCESS', data.data.data);
            });
        },
        req_getCoin({ commit }, input) {
            baseAPI().get('report/wallet', input).then((data) => {
                commit('GET_COIN_SUCCESS', data.data.coin);
            });
        },
        req_getHistoryGame({ commit }, input) {
            baseAPI()
                .get("report/game", {
                    params: input
                })
                .then(data => {
                    commit("GET_HISTORY_GAME_SUCCESS", data.data.data);
                });
        },
        req_getHistoryLogin({ commit }, input) {
            baseAPI().get('report/login', input).then((data) => {
                commit('GET_HISTORY_LOGIN_SUCCESS', data.data.data);
            });
        },

    },
    mutations: {
        DEPOSIT_SUCCESS(state, data) {
            this.dispatch('dashboard/req_getDashboard');
        },
        WITHDRAW_SUCCESS(state, data) {
            this.dispatch('dashboard/req_getDashboard');
        },
        GET_HISTORY_SUCCESS(state, data) {
            state.history = data.history;
            state.totalPages = data.total_page;
        },
        REGISTER_SUCCESS(data) {
            this.dispatch('dashboard/req_getDashboard');
        },
        CHANGEPASSWORD_SUCCESS(data) {
            this.dispatch('dashboard/req_getDashboard');
        },
        GET_HISTORY_GAME_SUCCESS(state, data) {
            state.history = data.history;
            state.totalPages = data.total_page;
        },
        GET_HISTORY_LOGIN_SUCCESS(state, data) {
            state.history = data.history;
            state.totalPages = data.total_page;
        },
    },

};