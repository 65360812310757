import baseAPI from '@/services/base';

export default {
    namespaced: true,
    state: () => ({
        balance: {},
        transaction: {},
        historyLogins: {},
        total: {},
        game: {},
        gameStatus: {},
        changePasswordStatus: {},
        info: {},
        agency: {},
        checkKYC: {},
        notiImage: {},
        notification: {},
        document: {},
        topLeader:{}
    }),
    getters: {
        Balance: state => state.balance,
        Transactions: state => state.transaction,
        HistoryLogins: state => state.historyLogins,
        Total: state => state.total,
        Game: state => state.game,
        Info: state => state.info,
        TopLeader: state => state.topLeader,
        Notification: state => state.notification,
        Document: state => state.document,
        CheckKYC: state => state.checkKYC,
        NotiImage: state => state.notiImage,
        Agency: state => state.agency,
        GameStatus: state => state.gameStatus,
        ChangePasswordStatus: state => state.changePasswordStatus,
    },
    actions: {
        req_getDashboard({ commit }, input) {
            baseAPI()
                .get(`auth/dashboard?page=${input}`)
                .then(response => {
                    commit("SET_DASHBOARD", response.data.data);
                });
        },
        req_getDocument({ commit }) {
            baseAPI()
                .get(`document`)
                .then(response => {
                    commit("SET_DOCUMENT", response.data);
                });
        },
        req_getNotification({ commit }) {
            baseAPI()
                .get(`posts-notifi`)
                .then(response => {
                    commit("SET_NOTIFICATION", response.data);
                });
        },
        req_getAgency: function({ commit }) {
            baseAPI()
                .get(`game/agency`)
                .then(response => {
                    commit("SET_AGENCY", response.data.data);
                });
        },
        req_getNotiImage: function({ commit }) {
            baseAPI()
                .get(`notification?type=2`)
                .then(response => {
                    commit("SET_NOTIIMAGE", response.data.data);
                });
        },
        req_buyAgency: function({ commit }, input) {
            baseAPI()
                .post(`game/buy-agency`, input)
                .then(response => {
                    commit("buyAgencySuccess", response.data.data);
                    // dispatch('req_getAgency');
                });
        },
        req_postWithdrawBonus: function({ commit }, input) {
            baseAPI()
                .post(`game/withdraw-bonus-birthday`, input)
                .then(response => {
                    commit("postWithdrawBonusSuccess", response.data.data);
                    // dispatch('req_getAgency');
                });
        },
        // KYC
        req_kyc({ commit }, input, ) {
            let formData = new FormData();
            formData.append('passport', input.passport);
            formData.append('passport_image', input.passport_image);
            formData.append('passport_image_selfie', input.passport_image_selfie);

            // Request
            baseAPI()
                .post("member/post-kyc", formData, { headers: { 'Content-Type': 'multipart/form-data', } })
                .then(function(data) {
                    commit('updateKYCSuccess', data);
                })
        },
        // req_changePassword: function({ dispatch }, input) {
        //     baseAPI()
        //         .post(`profile/change-password`, input)
        //         .then(response => {
        //             //dispatch('req_changePassword');
        //         });
        // }
    },
    mutations: {
        SET_DASHBOARD(state, data) {
            state.historyLogins = data.loginHistory;
            state.transaction = data.tranHistory.data;
            state.balance = data.balance;
            state.total.member = data.totalMember;
            state.total.sub = data.totalSub;
            state.game = data.url_game;
            state.gameStatus = data.game_status;
            state.info = data.info;
            state.topLeader = data.topLeader;
            state.checkKYC = data.checkKYC;
            state.changePasswordStatus = data.change_password;
        },
        SET_DOCUMENT(state, data) {
            state.document = data.data;
        },
        SET_NOTIFICATION(state, data) {
            state.notification = data.data;
        },
        SET_NOTIIMAGE(state, data) {
            state.notiImage = data;
        },
        SET_AGENCY(state, data) {
            state.agency = data.Game;
        },
        updateKYCSuccess(state, data) {
            state.checkKYC = data.checkKYC;
        },
        buyAgencySuccess(state, data) {
            this.dispatch('dashboard/req_getAgency');
        },
        postWithdrawBonusSuccess(state, data) {
            this.dispatch('dashboard/req_getAgency');
            this.dispatch('dashboard/req_getDashboard');
        },
    }
};
