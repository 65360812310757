import baseAPI from "@/services/base";
export default {
  namespaced: true,
  state: () => ({
    listSubject: {},
    listTicket: {},
    detail_ticket: {}
  }),

  getters: {
    ListSubject: state => {
      return state.listSubject;
    },
    ListTicket: state => {
      return state.listTicket;
    },
    DetailTicket: state => {
      return state.detail_ticket;
    }
  },

  actions: {
    req_postSubject: async function({ commit, dispatch }, input) {
      baseAPI()
        .post("ticket/post-ticket", input)
        .then(function(response) {
          if (response) {
            commit("SendTicketSuccess");
            dispatch("req_getTicket");
          }
        });
    },
    req_getTicket: function({ commit }) {
      baseAPI()
        .get(`ticket`)
        .then(response => {
          commit("GetTicketSuccess", response.data.data);
        });
    },
    req_getTicketDetail: async function({ commit }, input) {
      baseAPI()
        .get("ticket/get-ticket-detail/" + input)
        .then(function(response) {
          if (response) {
            commit("SET_DETAIL_TICKET", response.data.data);
          }
        });
    },
    req_postTicketMessage: async function({ commit }, input) {
      baseAPI()
        .post("ticket/post-ticket", input)
        .then(function(response) {
          if (response) {
            commit("SEND_MESSANGE_SUCCESS");
          }
        });
    }
  },

  mutations: {
    SendTicketSuccess: function() {},
    GetTicketSuccess: function(state, data) {
      state.listTicket = data.list_ticket;
      state.listSubject = data.subject_ticket;
    },
    SET_DETAIL_TICKET: function(state, data) {
      state.detail_ticket = data.list_detail;
    },
    SEND_MESSANGE_SUCCESS: async function() {}
  }
};
